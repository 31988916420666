import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Team from "../components/Team";
import { head } from 'lodash'

export const AboutPageTemplate = ({ title, content, contentComponent, teams, bannerImg }) => {
  const PageContent = contentComponent || Content
  const bannerImgSrc = bannerImg.childImageSharp.fluid.src
  return (
    <>
    <div className="title" style={{
        background: `url(` + bannerImgSrc +`) no-repeat`,
        backgroundSize: `cover`,
        backgroundPosition: `center 25%`,
    }}>
      <div className="container">
        <div className="columns is-gapless">
          <div className="column is-1"></div>
          <div className="column is-10">
            <h1 className="title has-text-white is-size-3 has-text-weight-bold is-bold-light" style={{
              marginBottom: `0`,
              marginTop: `15vh`,
              padding: `1rem`,
              background: `rgba(0,0,0,.5)`,
              lineHeight: `3rem`,
              height: `4.5rem`,
            }}>
              {title}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <section className="section section--gradient content">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <PageContent className="content" content={content} />
            <Team items={teams} />
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        bannerImg={post.frontmatter.bannerImg}
        teams={post.frontmatter.teams}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        bannerImg {
          childImageSharp {
            fluid(quality: 50, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        teams {
          sectionTitle
          people {
            title
            subtitle
            img {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
