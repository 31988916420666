import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const TeamGrid = ({ items }) => (
  <div className="columns is-multiline">
    {items.map(item => (
      <div key={item.sectionTitle} className="column is-12">
        
          <h2>{item.sectionTitle}</h2>
          {item.people.map(person => (
            <section
              className="section columns"
            >
            <div key={person.title} className="column is-3">
            <div
              style={{
                width: '100%',
                display: 'inline-block',
              }}
            >
                <a href={person.link} target="_blank">
                  <PreviewCompatibleImage imageInfo={person.img} />
              </a>
            </div>
          </div>
          <div className="column is-9">
              <h3>{person.title}</h3>
              <h4>{person.subtitle}</h4>
            <div
                  dangerouslySetInnerHTML={{ __html: person.description }} 
            />
          </div>
            </section>
          ))}
      </div>
    ))}
  </div>
)

// TeamGrid.propTypes = {
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//       text: PropTypes.string,
//     })
//   ),
// }

export default TeamGrid
